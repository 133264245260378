import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Hr, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Noir-Tattoo-Studio
			</title>
			<meta name={"description"} content={"Entfesseln Sie Ihren inneren Künstler im Noir-Tattoo-Studio"} />
			<meta property={"og:title"} content={"Noir-Tattoo-Studio"} />
			<meta property={"og:description"} content={"Entfesseln Sie Ihren inneren Künstler im Noir-Tattoo-Studio"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header />
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Leistungsübersicht
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Im Noir-Tattoo-Studio sind wir stolz darauf, eine große Auswahl an hochwertigen Tattoo-Dienstleistungen anzubieten, die Ihrem einzigartigen Stil und Ihren Vorlieben entsprechen. Unsere erfahrenen Künstler sind bestrebt, Ihre Ideen in schöne, bleibende Kunst zu verwandeln. Mit unserem Streben nach Perfektion garantieren wir, dass jedes Tattoo zu einem Kunstwerk wird, das Sie für immer schätzen werden.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/do-make-amazing-tattoo-sleeve.png?v=2024-07-11T13:29:40.434Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/668fb61b1d712a00233610e8/images/do-make-amazing-tattoo-sleeve.png?v=2024-07-11T13%3A29%3A40.434Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb61b1d712a00233610e8/images/do-make-amazing-tattoo-sleeve.png?v=2024-07-11T13%3A29%3A40.434Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb61b1d712a00233610e8/images/do-make-amazing-tattoo-sleeve.png?v=2024-07-11T13%3A29%3A40.434Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb61b1d712a00233610e8/images/do-make-amazing-tattoo-sleeve.png?v=2024-07-11T13%3A29%3A40.434Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb61b1d712a00233610e8/images/do-make-amazing-tattoo-sleeve.png?v=2024-07-11T13%3A29%3A40.434Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb61b1d712a00233610e8/images/do-make-amazing-tattoo-sleeve.png?v=2024-07-11T13%3A29%3A40.434Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb61b1d712a00233610e8/images/do-make-amazing-tattoo-sleeve.png?v=2024-07-11T13%3A29%3A40.434Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="30%"
				md-width="100%"
				font="--headline3"
			>
				<Text
					font="--headline3"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Über unsere Meister
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="70%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--headline5"
				>
					Im Noir-Tattoo-Studio sind unsere Künstler das Herz und die Seele unserer Organisation. Jeder Künstler bringt eine einzigartige Kombination aus Talent, Kreativität und Leidenschaft mit und sorgt dafür, dass jedes Tattoo, das er kreiert, ein wahres Meisterwerk ist. Unser Team besteht aus erfahrenen Fachleuten, die sich auf eine Vielzahl von Stilen spezialisiert haben, von traditionell und neotraditionell bis hin zu realistisch und abstrakt. Sie lernen und verbessern sich ständig und bleiben immer auf dem neuesten Stand der Technik und Trends in der Tattoo-Branche. Unsere Künstler sind viel mehr als nur Tätowierer; Sie sind die Geschichtenerzähler, die Ihre Ideen in Kunstwerke verwandeln, die Sie ein Leben lang mit Stolz tragen werden.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" margin="0px auto 0px auto" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="30%"
				padding="0px 40px 0px 15px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 60px 0px"
			>
				<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Unsere Tattoo-Angebote
				</Text>
				<Text margin="0px 0px 1rem 0px" color="--lightD2" font="--base">
					Bei Noir-Tattoo-Studio bieten wir eine breite Palette an Tattoo-Dienstleistungen an, die auf alle Ihre Bedürfnisse zugeschnitten sind. Egal, ob Sie ein kleines, einfaches Design oder ein Design mit langen Ärmeln suchen, unsere talentierten Künstler verfügen über das Können und die Kreativität, um es zum Leben zu erwecken. Hier finden Sie unser Angebot im Detail:
				</Text>
				<Text margin="auto 0px 0px 0px" color="--lightD2" font="--base">
					Kontaktieren Sie uns noch heute oder besuchen Sie unser Studio, um alle von uns angebotenen Optionen zu erkunden und das perfekte Tattoo für Sie zu finden.
				</Text>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="68%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Individuelle Gestaltung von Tattoos
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Unsere individuellen Tattoo-Designs sind maßgeschneidert, um Ihre Persönlichkeit und Vision widerzuspiegeln. Wir arbeiten eng mit Ihnen zusammen, um ein einzigartiges Kunstwerk zu schaffen, das Ihre Geschichte erzählt. Persönliche Beratung: Unsere Künstler sprechen mit Ihnen, um Ihre Ideen und Vorlieben zu verstehen und sicherzustellen, dass das endgültige Design genau Ihren Vorstellungen entspricht. Skizzieren: Basierend auf Ihren Eingaben erstellen wir detaillierte Skizzen, die Ihr Konzept zum Leben erwecken. Endgültige Designgenehmigung: Wir überprüfen die Skizzen und holen Ihre Genehmigung ein, bevor wir mit dem Tattoo-Prozess fortfahren.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Schwarze und graue Tattoos
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Für diejenigen, die die klassische Eleganz schwarzer und grauer Tattoos schätzen: Unsere talentierten Künstler beherrschen diesen zeitlosen Stil. Schattierungstechniken: Professionelle Schattierungstechniken verleihen Ihrem Tattoo Tiefe und Realismus. Aufwendige Details: Feine Linien und aufwendige Details, die Ihr Tattoo hervorheben. Vielseitiges Design: geeignet für Porträts, Landschaften und abstrakte Kunst.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Bunte Tätowierungen
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Verleihen Sie Ihrer Körperkunst einen Hauch von Farbe mit unseren atemberaubenden, farbenfrohen Tattoos, die mit Präzision und Kreativität erstellt wurden. Kräftige Farben: Verwendung hochwertiger Tinten, die langlebige und lebendige Farben gewährleisten. Farbmischung: Techniken zum sanften Mischen von Farben für ein natürliches Aussehen. Benutzerdefinierte Paletten: Personalisierte Farbpaletten passend zu Ihrem Design.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Verdeckte Tätowierungen
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Verwandeln oder aktualisieren Sie Ihre alten Tattoos mit unseren fachmännischen Deckungsdiensten und ermöglichen Sie Ihnen einen Neuanfang mit einem neuen Design. Kreative Lösungen: innovative Ansätze zur effektiven Abdeckung vorhandener Tattoos. Schichttechnik: Verwenden Sie Schichten und Schattierungen, um alte Tätowierungen zu maskieren. Personalisierte Designs: Individuelle Designs, die Ihr Cover ergänzen und aufwerten.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Kleine und minimalistische Tattoos
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Unsere kleinen und minimalistischen Tattoos sind perfekt, um Schlichtheit zum Ausdruck zu bringen und einen subtilen und eleganten Touch zu erzeugen. Präzise Linien: Präzision beim Erstellen feiner Linien und kleiner Details. Elegantes Design: Minimalistisches Design mit tiefer Bedeutung. Schnelle Sitzungen: Ideal für diejenigen, die ein schnelles und schmerzloses Erlebnis suchen.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Tattoopflege
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Es ist von entscheidender Bedeutung, dass Ihr Tattoo richtig verheilt. Unsere umfassenden Pflegerichtlinien helfen Ihnen dabei, die besten Ergebnisse zu erzielen. Personalisierte Anleitung: Detaillierte Nachpflegeanleitung speziell für Ihr Tattoo. Hochwertige Lebensmittel: Empfehlungen für die besten Lebensmittel zur Förderung der Genesung. Kontinuierlicher Support: Kontinuierlicher Support zur Lösung aller Fragen oder Probleme während des Wiederherstellungsprozesses.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 0px 0px" />
			</Box>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/5b5399877f2b8c28d41c77528bb765f1.jpg?v=2024-07-11T13:29:40.443Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Sind Sie bereit, Ihre Vision in die Realität umzusetzen?
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Machen Sie den ersten Schritt zu Ihrem neuen Tattoo, indem Sie das Noir-Tattoo-Studio besuchen. Unsere talentierten Künstler freuen sich darauf, mit Ihnen zusammenzuarbeiten und ein Kunstwerk zu schaffen, das Sie ein Leben lang mit Stolz tragen werden. Vereinbaren Sie noch heute einen Termin und wir helfen Ihnen dabei, Kunstwerke zu kreieren, eine Mascara nach der anderen.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Kontakte
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});